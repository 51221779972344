
  <div fxLayout="column" fxLayoutAlign.xs="center center" style="max-width: 900px;" [ngStyle]="{'margin':'auto'}">
    <div style="width: 100px;padding-top: 50px;">
      <img class="logo" src="assets/kool.png">
      <img class="logo" src="assets/delivery.png">
    </div>
  </div>
  <div fxLayout="row" style="max-width: 900px;margin: auto;" fxLayoutAlign.xs="center center" fxLayout.xs="column">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="45" fxFlex.sm="45" fxFlex.xs="100">

      <img style="padding-bottom: 50px;" [ngStyle]="{'width':'100%'}" [ngStyle.xs]="{'padding.px': 20,'width':'80%'}"
        fxLayout.xs="column" fxLayout.sm="column" fxLayoutAlign.xs="center center" src="assets/look.jpg">
      <div fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="20px">
        <a fxLayout.xs="column" href="https://apps.apple.com/eg/app/kool-delivery/id1545409489"> <img class="store-img"
            src="assets/btn1.png"></a>
        <a href="https://play.google.com/store/apps/details?id=com.mohamedhadiji.kool&hl=fr"> <img class="store-img"
            src="assets/btn2.png"></a>
      </div>
    </div>
    <div fxFlex="10"></div>
    <div  style=" padding:20px" fxLayout.xs="column" fxFlex="45" fxFlex.sm="45" fxLayout.sm="column" fxFlex.xs="100">
      <img src="assets/phone.png" style="width:100%;">
    </div>
  </div>
